.Peinture {
  display: flex;
  position: relative;
  box-sizing: content-box;
  list-style: none;
  padding: 5px;
  /* border-top: 1px solid #444; */
  border-bottom: 1px solid #444;
  align-items: center;
}
.Peinture-Couleur {
  display: block;
  height: 120px;
  flex: 0 0 120px;
  position: relative;
  border: 1px solid #333;
  box-shadow: 0 0 10px #000;
  border-radius: 50%;
  font-size: 300%;
  color: #333;
}
.Peinture-Noms * {
  display: block;
  padding-left: 8px;
}
.Peinture-Noms strong {
  font-size: 70%;
  color: orange;
  /* background-color: yellow; */
  padding-bottom: 4px;
}
.Peinture-Detail {
  flex: 1;
  text-align: right;
}
.Peinture-Detail>span {
  position: absolute;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 60%;
  bottom: 7px;
  right: 7px;
  text-align: right;
}
.Peinture-Stock {
  position: absolute;
  top: 4px;
  right: 3px;
}